import React, { useEffect } from 'react';
import { setupWorker } from 'msw/browser';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import {
  PubSub,
  EVENTS,
  SUBSCRIPTION_ROUTES,
  CART_ROUTES,
  LANGUAGES,
  CONFIG_ROUTES,
  ChangeProductSubscriptionEventDataType,
  UserStore,
  queryClient,
  CHECKOUT_ROUTES,
} from '@rc-d2c/context-manager';

import Layout from '../components/Layout';
import '../styles/global.scss';
import { handlers } from '../helpers/mswHandlers';
import { getCurrentEnv } from '../helpers/getCurrentEnv';

export const worker = setupWorker?.(...handlers);
import { useStoreCountry } from '../helpers/store';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const { push } = useRouter();
  const { storeCountry } = useStoreCountry();
  useEffect(() => {
    const isClient = 'undefined' !== typeof window;

    if (isClient) {
      if (UserStore.getState().isMswEnable) {
        //@ts-ignore context exist.
        !worker.context.isMockingEnabled && worker.start();
        UserStore.getState().setD2cToken('d2ctoken');
        UserStore.getState().setData({
          accessToken: 'accessToken',
          profile: {
            name: 'Yogi Test',
            email: 'sub_revamp@outlook.fr',
          },
        });
      } else {
        //@ts-ignore context exist.
        !!worker.context.isMockingEnabled && worker.stop();
      }
    }
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    let subscriptions = [];

    // ----- setup common configuration -----
    PubSub.publish(EVENTS.SET_GLOBAL_SETTINGS_CONFIG, {
      country: storeCountry,
      language: LANGUAGES.frFR,
      apiEnv: getCurrentEnv(),
      withLegacyApis: process.env.NEXT_PUBLIC_LEGACY_APIS === 'true',
      routes: {
        [CONFIG_ROUTES.HOMEPAGE]: '/',
        [CONFIG_ROUTES.CONTACT_SUPPORT]: '/',
      },
    });

    // ----- setup cart configuration -----

    PubSub.publish(EVENTS.SET_CART_ROUTES, { [CART_ROUTES.PRODUCT_FINDER]: '/product-finder', [CART_ROUTES.CART_PAGE]: '/cart' });

    // ----- setup checkout configuration -----

    PubSub.publish(EVENTS.SET_CHECKOUT_ROUTES, {
      [CHECKOUT_ROUTES.CHECKOUT]: '/checkout',
      [CHECKOUT_ROUTES.CONFIRMATION]: '/checkout/confirmation',
    });

    // ----- setup subscription configuration -----

    PubSub.publish(EVENTS.SET_SUBSCRIPTION_ROUTES, {
      [SUBSCRIPTION_ROUTES.CONFIRM_PLAN]: '/subscriptions/detail/${id}/confirm-plan/${newProductId}',
      [SUBSCRIPTION_ROUTES.DETAIL]: '/subscriptions/detail/${id}',
      [SUBSCRIPTION_ROUTES.EDIT_DATE]: '/subscriptions/detail/${id}/edit-date',
      [SUBSCRIPTION_ROUTES.EDIT_FREQUENCY]: '/subscriptions/detail/${id}/edit/frequency',
      [SUBSCRIPTION_ROUTES.EDIT_ADDRESSES]: '/subscriptions/detail/${id}/edit/addresses',
      [SUBSCRIPTION_ROUTES.EDIT_PAYMENT_METHODS]: '/subscriptions/detail/${id}/edit/payment-methods',
      [SUBSCRIPTION_ROUTES.EDIT]: '/subscriptions/detail/${id}/edit',
      [SUBSCRIPTION_ROUTES.LIST]: '/subscriptions/list',
      [SUBSCRIPTION_ROUTES.NUTRITION_ADVISOR_APPOINTMENT]: '/subscriptions/detail/${id}/nutrition-advisor/appointment',
      [SUBSCRIPTION_ROUTES.NUTRITION_ADVISOR_SCHEDULE]: '/subscriptions/detail/${id}/nutrition-advisor/schedule',
      [SUBSCRIPTION_ROUTES.NUTRITION_ADVISOR]: '/subscriptions/detail/${id}/nutrition-advisor',
      [SUBSCRIPTION_ROUTES.SERVICES]: '/subscriptions/detail/${id}/services/${serviceName}',
    });

    // ----- setup subscription events listeners -----

    subscriptions.push(
      PubSub.subscribe(EVENTS.CHANGE_PRODUCT_SUBSCRIPTION, (_, data) => {
        const { id, species } = data as ChangeProductSubscriptionEventDataType;
        sessionStorage.setItem('changeProductSubscriptionId', JSON.stringify(id));
        sessionStorage.setItem('changeProductSubscriptionSpecie', JSON.stringify(species));
        push(`/PLP`);
      }),
    );

    subscriptions.push(
      PubSub.subscribe(EVENTS.CHANGE_PRODUCT_SUBSCRIPTION_END, () => {
        sessionStorage.removeItem('changeProductSubscriptionId');
      }),
    );

    subscriptions.push(
      PubSub.subscribe(EVENTS.CREATE_PET, (_, subscriptionId) => {
        const redirectUrl = window.location.pathname;

        sessionStorage.setItem('subLinkPetRedirectUrl', JSON.stringify(redirectUrl));
        sessionStorage.setItem('subLinkPetSubscriptionId', JSON.stringify(subscriptionId));
        push(`/my-account/pets/manage-pet?persistent_deeplink=pets/manage-pet`);
      }),
    );

    subscriptions.push(
      PubSub.subscribe(EVENTS.SELECT_PET, (_, subscriptionId) => {
        const redirectUrl = window.location.pathname;

        sessionStorage.setItem('subLinkPetRedirectUrl', JSON.stringify(redirectUrl));
        sessionStorage.setItem('subLinkPetSubscriptionId', JSON.stringify(subscriptionId));
        push(`/my-account/pets?persistent_deeplink=pets`);
      }),
    );

    subscriptions.push(
      PubSub.subscribe(EVENTS.LINK_PET_SUBSCRIPTION_END, () => {
        sessionStorage.removeItem('subLinkPetRedirectUrl');
        sessionStorage.removeItem('subLinkPetSubscriptionId');
      }),
    );

    return () => {
      subscriptions.forEach((subscription) => PubSub.unsubscribe(subscription));
    };
  }, [push]);

  return (
    <SessionProvider session={session}>
      <Layout {...pageProps}>
        <Component {...pageProps} />
      </Layout>
    </SessionProvider>
  );
}

export default MyApp;
